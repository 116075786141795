import React from 'react';
import Routes from './Routes';
import '../styles/App.css';
export let dispatch = {};

function touchHandler(event){
  if(event.touches.length > 1){
      //the event is multi-touch
      //you can then prevent the behavior
      event.preventDefault()
  }
}


function App() {
  // window.addEventListener("touchstart", touchHandler, false);
  return (
    <div className="App">
        <div className="container-box">
          <Routes />
        </div>
    </div>
  );
}

export default App;
