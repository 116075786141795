import { StorageService, RequestService, AccountService } from './';
import config from '../config';
import { dispatch } from '../App';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom'

export class AuthService {
  
  // check is logged in user  
  isLoggedIn() {
    const token = this.getToken();
    return !!(token);
  }
  
  // get token from local storage
  getToken() {
    const token = StorageService.get('token');
    return token;
  }
  
  // get account info from local storage
  getAccount() {
    const account = StorageService.get('account');
    return account;
  }

 async userRegistration(data){
   try{
      let url = `${config.api}/user/register`;

      const user = await RequestService.post(url, data);
      if(user.error){
        throw user.error;
      }
      if(typeof user.token !== 'undefined'){
        // Store token
        StorageService.set('token', user.token);
        return true;
    }
   } catch(error){
     throw error;

   }
 }
  // login 
  async authenticateEmailPassword(username, password) {
    try {
      let url = `${config.api}/user/login`;

      const token = await RequestService.post(url, { username, password });

      
      if (token.error) {
        throw token.error;
      }

      if(typeof token.token !== 'undefined'){
          // Store token
          StorageService.set('token', token.token);
      }

      const account = await RequestService.get(`${config.api}/user/login`);


      if(account){
        StorageService.set('account', account);
        return true;
      }
      return false;

      
    } catch (error) {
      throw error;
    }
  }
  
  // check user login status 
  async checkLogin() {
    try {
      let url = `${config.api}/user/login`;

      let check = await RequestService.get(url).then((data)=>{
         if(typeof data.token !== 'undefined'){
            StorageService.set('token', data.token);
  
          }
          
          if (data.message !== undefined) {
              if (data.message === "Unauthorized request") {
                  this.logout();
              }
          }
          return data;
      });
      return check;
     
    } catch (error) {
      throw error;
    }
  }
  
  // ----- // 
  async authenticate2fa(token) {
    try {
      const response = await AccountService.verify2fa(token);

      // Store twofa response
      StorageService.set('twofa', response.data || 'Ok');

    } catch (error) {
      throw error;
    }
  }

  // logout user and clear storage 
  async logout(props) {
    try {
      console.log(this);
      let url = `${config.api}/user/logout`;
      const logout = await RequestService.post(url, {});
      StorageService.clear();
      StorageService.clearEntireStorage();
      window.location.href = '/'

    } catch (error) {
      throw error;
    }

  }
}

export default new AuthService();