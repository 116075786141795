import { RequestService, AuthService } from './';
import config from '../config';

export class AccountService {

  async create(data) {
    try {
      const url = `${config.api}/accounts`;

      const account = await RequestService.post(url, data);
      if (account.error) {
        throw account.error;
      }

      return account.data;
    } catch (error) {
      throw error;
    }
  }

  async get(id) {
    try {
      const url = `${config.api}/accounts/${id}`;

      const account = await RequestService.get(url);
      if (account.error) {
        throw account.error;
      }

      return account.data;
    } catch (error) {
      throw error;
    }
  }

  async getSingleUser(id) {
    try {
      const url = `${config.api}/${config.namespace}/users/${id}`;

      const users = await RequestService.get(url);
      if (users.users) {
        throw 'There is no user with given id.'
      }
      return users;
    } catch (error) {
      throw error;
    }
  }
   /**
   * Get all users that followed by the logged user
   */
  async getFollowingUsers(){  
    try {
      let user = await AuthService.checkLogin();
      let url = `${config.api}/${config.namespace}/users-following/${user.id}`;

      const users = await RequestService.get(url);
      if (users.error) {
        throw users.error;
      }

      return users.users;
    } catch (error) { 
      throw error;
    }
  }
  /**
   * Get all users followers
   */
  async getUserFollowers(){  
    try {
      let user = await AuthService.checkLogin();
      let url = `${config.api}/${config.namespace}/users-followers/${user.id}`;

      const users = await RequestService.get(url);
      console.log(users)
      if (users.error) {
        throw users.error;
      }

      return users.users;
    } catch (error) { 
      throw error;
    }
  }


  async getUsers(props){
    let { term=null, limit=40, offset=0 } = props;
    try {
      let url = `${config.api}/${config.namespace}/all-users?limit=${limit}&offset=${offset}`;

      if(term !== null){
        url += `&search=${term}`;
      }
      
      const users = await RequestService.get(url);
    
      if (users.error) {
        throw users.error;
      }

      return users.users;
    } catch (error) { 
      throw error;
    }
  }
  
    /**
   * Follow / Unfollow the user with given id 
   * @param {number} id 
   * @param {boolean} action Bool [ true - follow the user|| false - remove it from following ]
   */
  async followingPlayer(id, action){
    try {
      const url = `${config.api}/${config.namespace}/following`;

      const _data = {
        id_following: id,
      }

      // if(action){
      _data['follow'] = action
      // }
      const unfovoirte = !action ? 
          await RequestService.delete(url+`?id_following=${id}`, _data) : 
          await RequestService.put(url, _data);

      if (unfovoirte.status !== 204){
        return false;
      }
      return true;

    } catch (error){
      return false;
    }
  }

  
  async getUsersNearMe(props){
    let { term=null, limit=40, offset=0 } = props;
    try {
      let url = `${config.api}/${config.namespace}/all-users?limit=${limit}&offset=${offset}`;
      
      if(term !== null){
        url += `&search=${term}`;
      }
      const users = await RequestService.get(url);
      if (users.error) {
        throw users.error;
      }

      return users.users;
    } catch (error) {
      throw error;
    }
  }

  async handicap() {
    try {
      const url = `${config.api}/${config.namespace}/federgolf/handicap`

      const handicap = await RequestService.get(url);

      if (!handicap.handicaps){
        throw 'There is no data for handicap!';
      }
      return handicap.handicaps;

    } catch (error){
      throw error;
    }
  }
  
  async me() {
    try {
      const url = `${config.api}/accounts/me`;

      const account = await RequestService.get(url);
      if (account.error) {
        throw account.error;
      }

      return account.data;
    } catch (error) {
      throw error;
    }
  }

  async changePassword(data) {
    
    try {
      const url = `${config.api}/user/password/change`

      const password = await RequestService.post(url, data);
      console.log(password);
      
      if (!password){
        throw 'Password is not changed!';
      }
      return password;

    } catch (error){
      throw error;
    }
  }
  
  /**
   * Update profile information 
   * 
   * @param {object} data 
   */
  async updateProfile(data) {
    
    try {
      const url = `${config.api}/${config.namespace}/profile`;
      console.log(url);
      const profile = await RequestService.patch(url, data);
      
      if (profile.changed.length === 0){
        throw 'Profile not changed!';
      }
      return profile;

    } catch (error){
      throw error;
    }
  }

  async edit(id, body) {
    try {
      const url = `${config.api}/accounts/${id}`;

      const account = await RequestService.put(url, body);
      if (account.error) {
        throw account.error;
      }

      return account.data;
    } catch (error) {
      throw error;
    }
  }

  async enable2fa() {
    try {
      const url = `${config.api}/accounts/2fa`;

      const response = await RequestService.post(url);
      if (response.error) {
        throw response.error;
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async verify2fa(token) {
    try {
      const url = `${config.api}/accounts/2fa/verify`;

      const response = await RequestService.post(url, { token });
      if (response.error) {
        throw response.error;
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

}

export default new AccountService();