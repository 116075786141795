const updateObjectPropById = (array, setFunct, id, prop, val) => {
    let _array = [...array];
    
    _array.forEach(item => {
        if(item.id === id){
            item[prop] = val;
            console.log(item)
        }
    });

    setFunct(_array);          
}
export default updateObjectPropById;
