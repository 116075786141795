import { RequestService, AuthService } from './';
import config from '../config';

export class CourseService {

  
  /**
   * Make it favorite or remove from favorites
   * @param {number} id 
   * @param {boolean} action Bool [ true - make it favorite || false - remove it from favorites ]
   */
  async favoriteAction(id, action){
    try {
      const url = `${config.api}/${config.namespace}/favorite-courses/${id}`

      const _data = {
        course_id: id,
        favorite: action
      }
      const unfavorite = await RequestService.put(url, _data);

      if (unfavorite.status !== 204){
        return false;
      }
      return true;

    } catch (error){
      return false;
    }
  }

  /**
   * Get all favourite courses for a logged user
   */
  async favouriteCourses() {
    try {
      const url = `${config.api}/${config.namespace}/favorite-courses`

      const favorite = await RequestService.get(url);
      if (!favorite.courses){
        throw 'There is no data for favorite courses!';
      }
      return favorite.courses;

    } catch (error){
      throw error;
    }
  }
 
  /**
  * Get courses by the chosen country and region id
  * @param {number} country 
  * @param {number} region 
  */
  async coursesByCountryAndRegion(country, region){
    try {
      const url = `${config.api}/${config.namespace}/courses/${country}/${region}`

      const favorite = await RequestService.get(url);
      if (!favorite.courses){
        throw 'There is no data for favorite courses!';
      }
      return favorite.courses;

    } catch (error){
      throw error;
    }
  }

  /**
   * Get all courses near logged user - sorted by distance
   */
  async getCoursesNearMe(){
    try {
      const tmp = await AuthService.checkLogin();
      const { lat, lon }  = tmp;
      const url = `${config.api}/${config.namespace}/courses/bylocation/${lat}/${lon}/50`
      const courses = await RequestService.get(url);
      console.log(courses)
      if (!courses.courses){
        throw 'There is no data for courses near you!';
      }
      return courses.courses;

    } catch (error){
      throw error;
    }
  }

  /**
   * Get courses by searched term
   * @param {string} term 
   * @param {number} limit 
   * @param {number} offset 
   */
  async getCourseByTerm(term){
    try {
      const url = `${config.api}/${config.namespace}/search-course/${term}?limit=100&offset=0&search=${term}`;

      const courses = await RequestService.get(url);
      if (!courses.courses){
        throw 'There is no coursers for a given term!';
      }
      return courses.courses;

    } catch (error){
      throw error;
    }
  }

  /**
   * Get all regions for a given country id
   * @param {number} id 
   */
  async getRegions(id){
    try{
      const url = `${config.api}/${config.namespace}/regions/${id}`

      const regions = await RequestService.get(url);
      
      if (!regions.regions){
        throw 'There is no data for countries!';
      }
      return regions.regions;

    } catch(error){
      throw error;
    }
  }

  /**
   * Get all countries
   */
  async getCountries(){
    try {
      const url = `${config.api}/${config.namespace}/countries`

      const countries = await RequestService.get(url);
      
      if (!countries.countries){
        throw 'There is no data for countries!';
      }
      return countries.countries;

    } catch (error){
      throw error;
    }
  }

  /**
   * Get single course by given id
   * @param {number} id 
   */
  async getSingleCourse(id){
    try {

      const url = `${config.api}/${config.namespace}/courses/${id}`

      const course = await RequestService.get(url);

      if (!course){
        throw 'There is no data for favorite courses!';
      }
      return course;

    } catch (error){
      throw error;
    }
  }

}

export default new CourseService();