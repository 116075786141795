import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faUserMinus } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarR } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';


import { formatDate } from '../../utils';

export default function Item(props) {
    let { id,  name, city, image, favorite, funct, functMessage, functType, distance, lat, lon, item } = props;

    /**
     * Rewrite the url
     * @param {string} functType 
     */
    const getUrl = (functType) => {
        if(['players', 'player', 'follow', 'unfollow'].indexOf(functType) !== -1){
            url = '/players';
        }else if(functType === 'tournament' || functType === 'near-tournament'){
            url = '/tournament';
        }else if(functType === 'messaging'){
            url = '/conversations';
        }else if(['courses', 'favorite', 'unfavorite'].indexOf(functType)){
            url = `/courses`;
        }
        return url += `/${id}`;
    }
    
    // Rewrite url
    let url = '';
    url = getUrl(functType);

    // temporary style - todo
    const visibility = {
        
    }
    // if(functType !== 'tournament'){
    visibility.visibility = 'hidden'
    // }
    const verticalAlign = {
        paddingTop: '10px',
        verticalAlign:'top'
    }
   
  const rightSide = (functType) => {
    switch(functType) {
        case 'unfavorite':
        case 'favorite':
            return  <div key={id} data-id={favorite} onClick={() => { if (window.confirm(functMessage)) funct(id, favorite ? false : true); }} className="active tournament-action tournament-action-1 click" >
                        <FontAwesomeIcon className="star far fa-star" icon={favorite ? faStar : faStarR } />
                    </div>;

        case 'players':
        case 'follow':
        
            if(favorite)return <div></div>;
            return  <div key={id} data-id={favorite} onClick={() => { if (window.confirm(functMessage)) funct(id, favorite ? false : true); }} className="active tournament-action tournament-action-1 click" >
                        <FontAwesomeIcon className="star far fa-star" icon={faUserPlus} />
                    </div>;
        case 'unfollow':
            
            if(!favorite)return <div></div>;
            return  <div key={id} data-id={favorite} onClick={() => { if (window.confirm(functMessage)) funct(id, favorite ? false : true); }} className="active tournament-action tournament-action-1 click" >
                <FontAwesomeIcon className="star far fa-star" icon={faUserMinus} />
            </div>;

        case 'near':
            return <div  className=" tournament-action distance">{parseInt(distance)}km</div>;
        case 'tournament':
                return <div  className=" tournament-action time">{formatDate(new Date(item.start))}</div>;
        case 'near-tournament':
            return <div  className=" tournament-action time">{parseInt(item.distance)}km</div>;
        case 'messaging':
            return  <div className="tournament-action arrow">
                        <FontAwesomeIcon className="star far fa-star" icon={faArrowRight} />
                    </div>;
        default:
            return <div></div>;
    }            

  }
  return (
      <>
        <div className="tournament" id={`tournament-${id}`} >
            <Link to={url}>
                <div className="tournament-image">
                    <img src={image || ''}  />
                </div>
                <div className="tournament-description" style={verticalAlign}>
                    <h3>{name || ''}</h3>
                    <p>{city || ''}</p>
                    <p style={visibility}>{city || ''}</p>
                </div>
            </Link>
                {rightSide(functType)}
        </div>
    </>
  );
}

