// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';

function useForm(validate) {
  const [touched, setTouched] = useState({});
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(touched && Object.keys(errors).length === 0);
  }, [errors, touched]);

  useEffect(() => {
    const _errors = validate(values);
    if (JSON.stringify(errors) !== JSON.stringify(_errors)) {
      setErrors(_errors);
    }
  }, [errors, validate, values]);

  const onChange = event => {
    // if (event.persist) {
    //   event.persist();
    // }
    const name = event.target.name;
    const value = event.target.value;

    if (!touched[name]) {
      setTouched({
        ...touched,
        [name]: true,
      });
    }

    const _values = {
      ...values,
      [name]: value,
    };
    if (JSON.stringify(values) !== JSON.stringify(_values)) {
      setValues(_values);
    }

    const _errors = validate(_values);
    if (JSON.stringify(errors) !== JSON.stringify(_errors)) {
      setErrors(_errors);
    }
  };

  return {
    values,
    touched,
    errors,
    valid,
    onChange,
  };
}

export default useForm;