import React from 'react';

export default function Modal(props) {
    let { isOpen, onClose, component } = props;
    let modalAction = isOpen ? 'active':'';
    return (
        <div className={modalAction + ` modal`}>
            <div  className={modalAction + ` modal__overlay`}  role="dialog" aria-labelledby="modal__title" aria-describedby="modal_desc">
                <div className="modal__wrap">
                    <label onClick={() => { onClose(); }} htmlFor="modal__trigger">&#10006;</label>
                    <h2 id="modal__title">{component.title}</h2>
                    <div id="modal__desc">
                        {component.body}
                    </div>
                </div>
            </div>
        </div>
        );
}

