import React from 'react';

export default function Placeholder(props) {
    let { type } = props;
    let numrows = 12;
    const courseItem = (i) => {
        return  <div key={i} className="skeleton-wrapper-inner">
                    <div className="skeleton-wrapper-body">
                    <div className="skeleton-avatar"></div>
                    <div className="skeleton-author"></div>
                    <div className="skeleton-label"></div>
                        <div className="skeleton-follow-head"></div>
                        <div className="skeleton-follow"></div>
                    </div>
                </div>
    };

    const getSkeleton = (type)=>{
        let content = '';
        switch (type) {
            case 'courses':
                content =  <div className="skeleton">
                            <div className="skeleton-wrapper">
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                        <div className="skeleton-avatar"></div>
                                        <div className="skeleton-author"></div>
                                        <div className="skeleton-label"></div>
                                        <div className="skeleton-follow-head"></div>
                                        <div className="skeleton-follow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                break;
            case 'player':
                content = <div className="skeleton">
                                <div className="skeleton-wrapper">
                                    <div className="skeleton-wrapper-inner">
                                        <div className="skeleton-wrapper-body">
                                            <div className="skeleton-profile-image"></div>
                                            <div className="skeleton-first-name"></div>
                                
                                            <div className="skeleton-follow-head first"></div>
                                            <div className="skeleton-follow first"></div>
                                            <div className="skeleton-follow-head second"></div> 
                                            <div className="skeleton-follow second"></div>
                                            <div className="skeleton-follow-head third"></div> 
                                            <div className="skeleton-follow third"></div>
                                
                                
                                            <div className="skeleton-first-count"></div>
                                            <div className="skeleton-second-count"></div> 
                                            <div className="skeleton-third-count"></div>
                                            <div className="skeleton-follower-avatar first"></div>
                                            <div className="skeleton-follower-avatar second"></div>
                                        
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                break;
            case 'messaging':
            case 'course':
                content =   <div className="skeleton">
                                <div className="skeleton-wrapper">
                                    <div className="skeleton-wrapper-inner">
                                    
                                        <div className="skeleton-wrapper-body">
                                            <div className="skeleton-course-image"></div>
                                            <div className="skeleton-course-name"></div>
                                            <div className="skeleton-course-address"></div>

                                            <div className="skeleton-map"></div>         

                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                break;
            case 'stats':
                content = <div className="skeleton">
                                <div className="skeleton-wrapper">
                                <div className="skeleton-wrapper-inner">
                                    <div className="skeleton-wrapper-body">
                                    <div className="skeleton-stats-label first"></div>
                                    <div className="skeleton-stats-label second"></div>
                                    <div className="skeleton-stats-label third"></div>
                                    <div className="skeleton-stats-label fourth"></div>
                            
                                    <div className="skeleton-map"></div>         
                                    
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                break;
            default:
                break;
            
            
        
        }
        return content;
    }
    return (
        <>
            
            { 
                getSkeleton(type)
           }
        
    </>
    );
}


           