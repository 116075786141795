import React from 'react';
import { AuthService } from '../../services';
import { Redirect } from 'react-router';

export default function ({ rule, children, redirectTo }) {
  switch (rule) {
    case 'authorized':
      return <>
        {
          (AuthService.isLoggedIn() && children) ||
          <>{redirectTo && <Redirect to={redirectTo} />}</>
        }
      </>;

    case 'unauthorized':
      return <>
        {
          (!AuthService.isLoggedIn() && children) ||
          <>{redirectTo && <Redirect to={redirectTo} />}</>
        }
      </>;

    default:
      return <></>;
  }
};