import React from 'react';
import { Link } from 'react-router-dom';

export default function Item(props) {
    let {   name, code, nr_regions, single_region } = props.item;
    let { type } = props;
    let url =   (typeof nr_regions === 'undefined') ? 
                `/courses/countries/${single_region}/${code}` :  
                    (nr_regions === 1) ?
                    `/courses/countries/${code}/${single_region}` : 
                    `/courses/countries/${code}`;

 
    let itemImage = `//golfer.digitalcube.rs/static/icons/${code}.svg`
    return (
        <>
        <Link to={url}>
            {type === 'country' ? 
                   
                <div key={code} className="tournament country">
                    {type === 'country' ? 
                        <div className="country-image" >
                            <img src={itemImage} alt="card-image" />
                        </div>
                        : ''
                    }
                    <div className="country-description" >
                        <h3>{name}</h3>
                    </div>
                </div>

                : 
                <div key={code} className="tournament country">
                    <div className="country-description-center" >
                        <h3>{name}</h3>
                    </div>
                </div>
            }
        </Link>
        
    </>
    );
}


           