import React from 'react';
export default function Loader(props) {
  return (
    <div className={`loading-container ${props.class || ''} `} style={props.style || ''}>
        <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div className="lds-text">{props.msg}</div>
    </div>
  );
}

