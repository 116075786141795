import React, { lazy, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { lazyLoad } from './utils';
import { Protected, Navigation } from './components';
import Conversations from './containers/Messaging/containers/Conversations/Conversations';


const Home = lazy((setActivePage) => import('./containers/Home'));
const Login = lazy(() => import('./containers/Login'));
const Registration = lazy(() => import('./containers/Registration'));
const Account = lazy(() => import('./containers/Account'));
const Stats = lazy(() => import('./containers/Stats'));
const Players = lazy(() => import('./containers/Players'));
const Courses = lazy(() => import('./containers/Courses'));
const Regions = lazy(() => import('./containers/Courses'));
const Countries = lazy(() => import('./containers/Courses'));
const Tournament = lazy(() => import('./containers/Tournaments'));
const Tournaments = lazy(() => import('./containers/Tournaments'));
const Messaging = lazy(() => import('./containers/Messaging'));

const Routes = () => {
  const [activePage, setActivePage] = useState('play');

  console.log(activePage);
  return (
    <>
      <Protected rule='authorized'>
        <Switch>
          <Route path='/play'  component={lazyLoad(Home)} />
          <Route path='/profile' component={lazyLoad(Account)} />
          <Route path='/stats' component={lazyLoad(Stats)} />
          <Route path='/players' component={lazyLoad(Players)} />
          <Route path='/courses' component={lazyLoad(Courses)} />
          <Route path='/tournaments' component={lazyLoad(Tournaments)} />
          <Route path='/tournament' component={lazyLoad(Tournament)} />
          <Route path='/conversations' component={lazyLoad(Messaging)} />
          <Redirect from='*' to='/tournaments' />
        </Switch>
        
        <Navigation modul="default" active={activePage}  />

      </Protected>

      <Protected rule='unauthorized'>
        <Switch>
          <Route path='/registration' exact component={lazyLoad(Registration)} />
          <Route path='/login' exact component={lazyLoad(Login)} />
          <Redirect from='*' to='/login' />
        </Switch>
      </Protected>
    </>
  );
};

export default Routes;