import axios from 'axios';
import { StorageService } from '.';

const API = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.response.use(response => {
  if (response.data) {
    return response.data;
  }
  return response;
}, error => {
  const _error = error.response.data && error.response.data.meta && error.response.data.meta.message;
  return {
    error: _error,
  };
});

API.interceptors.request.use(config => {
  const token = StorageService.get('token');
  if (token) {
    config.headers['Authorization'] = `${token}`;
  }

  return config;
}, error => Promise.reject(error));

export class RequestService {

  get = url => API.get(url);

  post = (url, body) => API.post(url, body);

  delete = url => API.delete(url);

  put = (url, body) => API.put(url, body);

  patch = (url, body) => API.patch(url, body);

}

export default new RequestService();