import { RequestService } from '.';
import config from '../config';

export class TournamentService {

  /**
   * Get all tournaments
   */
  async getTournaments(lat=null, lan=null){
    try {
      const url = `${config.api}/${config.namespace}/tournaments?lat=44&lon=23`
       
      // if(lat !== null && typeof lat !== 'undefined'){
        // url += `?lat=44.24&lon=22.33`;
      // }

      const tournaments = await RequestService.get(url);
      
      if (!tournaments.tournaments){
        throw 'There is no data for tournaments!';
      }
      return tournaments.tournaments;

    } catch (error){
      throw error;
    }
  }

  /**
   * Get single tournament by given id
   * @param {number} id 
   */
  async getSingleTournament(id){
    try {

      const url = `${config.api}/${config.namespace}/tournaments/${id}`

      const tournament = await RequestService.get(url);
      if (!tournament){
        throw 'There is no data for favorite tournaments!';
      }
      return tournament.tournament;

    } catch (error){
      throw error;
    }
  }

  /**
   * Get single tournament participants
   * @param {number} id 
   */
  
  async getTournamentParticipants(id){
    try {

      const url = `${config.api}/${config.namespace}/tournament/${id}/participants`

      const data = await RequestService.get(url);
      if (!data){
        throw 'There is no data!';
      }
      return data;

    } catch (error){
      throw error;
    }
  }

  /**
   * Subscribe to tournament with given id
   * @param {number} id - The unique id for the tournament
   * @param {object} data - The data (phone, name etc.)
   */
  async tournamentSubscribe(id, _data){
    try{
      const url = `${config.api}/${config.namespace}/tournament/${id}/subscribe`

      const data = await RequestService.put(url, {id: id, data:_data });
      if (!data){
        throw 'There is no data!';
      }
      return data;

    } catch (error){
      throw error;
    }
  }
}

export default new TournamentService();