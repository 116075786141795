import React from 'react';
export default function Switcher(props) {
    let { status, trigger, funct, functMessage } = props;
    return (
        <>
            
                <div className="switchers">
                    <label className="switch ">
                        <input type="checkbox" defaultChecked={ status ? 'checked' : ''}  />
                        <span   onClick={() => { if (trigger || window.confirm(functMessage)) funct(); }} 
                                className={status ? "slider round right" : "slider round left"}></span>
                    </label>
                </div>
                
        </>
    );
}


           