import React from 'react';
import { Link, NavLink } from 'react-router-dom'

export default function Navigation(props) {

    const module = typeof props.module !== 'undefined' ? props.module : '';
    const active = typeof props.active !== 'undefined' ? props.active : '';

    const tmpHome = ["favourites", "near", "all", "search"];
    const favourites = (active == "favourites" || active === 'following') ? "line active" : "line ";
    const near = active == "near" ? "line active" : "line ";
    const all = active == "all" ? "line active" : "line ";
    const search = active == "search" ? "line active" : "line ";
    
    switch(module){
        case 'courses':
            return (
                    <nav className="nav-tabs">
                        <ul>
                            <Link to="/courses" >
                                <li className={favourites}>Favorites</li>
                            </Link>
                            <Link to="/courses/near-me" >
                                <li className={near}>Near me</li>
                            </Link>
                            <Link to="/courses/countries" >
                                <li className={all}>All</li>
                            </Link>
                            <Link to="/courses/search">
                                <li className={search}>Search</li>
                            </Link>
                        </ul>
                    </nav>
                );
            break;
        
        case 'players':

                return (
                        <nav className="nav-tabs">
                            <ul>
                                <Link to="/players" >
                                    <li className={favourites}>Following</li>
                                </Link>
                                <Link to="/players/near-me">
                                    <li className={near}>Near me</li>
                                </Link>
                                <Link to="/players/search">
                                    <li className={search} >Search</li>
                                </Link>
                            </ul>
                        </nav>
                    );
                break;
    
        default:
         

           
            return (
                <div className="navigation">
                    <NavLink className="navigationUrl" to="/players" data-id="players" activeClassName="selected" >
                        <div className="first">
                            {/* <img style={styles.players} src={active ==='players' ? playersActive : players }/> */}
                            <div className="image"></div>
                            <p>Players</p>
                        </div>
                    </NavLink>
                    <NavLink className="navigationUrl" to="/conversations" data-id="message" activeClassName="selected">
                        <div  className="second">
                            {/* <img style={styles.message} src={active === 'message'  ? messageActive : message }/> */}
                            <div className="image"></div>
                            <p>Messages</p>
                        </div>
                    </NavLink>
                    <NavLink className="navigationUrl" to="/play"  data-id="play" activeClassName="selected">
                        <div  className="third">
                            {/* {(tmpHome.indexOf(active) !== -1) } */}
                            {/* <img style={styles.play} src={active === 'home' || active === '' ? playActive : play }/> */}
                            <div className="image"></div>
                            <p>Play</p>
                        </div>
                    </NavLink>
                    <NavLink className="navigationUrl" to="/profile" data-id="profile" activeClassName="selected">
                        <div  className="four">
                            {/* <img style={styles.profile} src={active === 'profile' ? profileActive : profile }/> */}
                            <div className="image"></div>
                            <p>Profile</p>
                        </div>
                    </NavLink>
                    <NavLink className="navigationUrl" to="/stats" data-id="stats" activeClassName="selected">
                        <div  className="five">
                            {/* <img style={styles.stats} src={active === 'stats' ? statsActive : stats }/> */}
                            <div className="image"></div>
                            <p>Stats</p>
                        </div>
                    </NavLink>
                </div>
            );
    }
}