
const imageFix = (data, type) => {
    
    if(data.length > 0){           
        for (let i = 0; i < data.length; i++) {
            let images = (typeof type !== 'undefined' && type === 'tournament') ? data[i].course.images : data[i].images;

    
            if (images.length === 0) {
                images.push("//www.1golf.eu/images/dynamic/011251/1200-1200-60-0-0-0.jpg");
            }else{
                if(typeof type !== 'undefined' && type === 'tournament'){
                    data[i].course.images[0] =`//www.1golf.eu${data[i].course.images[0]}`
                }else{
                    data[i].images[0] =`//www.1golf.eu${data[i].images[0]}`
                }
            }
        }
    }
    return data;
  }
  
export default imageFix;
  
  