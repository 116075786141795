import { RequestService, AuthService } from './';
import config from '../config';

export class MessagingService {

  
  /**
   * Send a new message to receiver
   * @param {number} id 
   */
  async sendMessage(id, message){
    try {
      const url = `${config.api}/${config.namespace}/messages/${id}/send`
      const _data = {
        id_receiver: id,
        message: message
      }
      const sendMessage = await RequestService.put(url, _data);

      if (sendMessage.id === undefined){
        return false;
      }
      return sendMessage.id;

    } catch (error){
      return false;
    }
  }

  /**
   * Get all conversations for the logged user
   */
  async getConversations() {
    try {
      const url = `${config.api}/${config.namespace}/messages`

      const conversations = await RequestService.get(url);
      if (!conversations.messages){
        throw 'There is no data for conversations!';
      }
      return conversations.messages;

    } catch (error){
      throw error;
    }
  }


  /**
   * Get single conversation for the logged user
   */
  async getSingleConversation(id) {
    try {
      const url = `${config.api}/${config.namespace}/messages/${id}?nr_messages=10000`

      const conversations = await RequestService.get(url);
      if (!conversations.messages){
        throw 'There is no messages for the conversation with given id!';
      }
      return conversations.messages;

    } catch (error){
      throw error;
    }
  }

}

export default new MessagingService();