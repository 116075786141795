// const formatDate = (date) => {
//   const month = date.getMonth();
//   const day = date.getDate();
//   const year = date.getFullYear();

//   return `${day}/${month}/${year}`;
// };
const formatDate = (date) => {
  var monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
}

export default formatDate;

