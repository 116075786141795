import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export default function Header(props) {
  let { title, goBack } = props;
  
  return (
    <header >
        <div className="back-btn">
          <FontAwesomeIcon onClick={goBack} className="icon left" icon={faAngleLeft} />
        </div>
        {title}
    </header>
  );
}

// <div className="container-box">
// <div style="height:20px;background-image: linear-gradient(to right, #1fbfb0 0, #02b7ab 100%);
// "></div>
// </div>