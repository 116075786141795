let dev = false;
let test = false;
let prod = false;

try {
  let core = window.location.hostname.split('.');
  core.shift();

  core = core.join('.');

  test = core.indexOf('-test') > -1;
  dev = core.indexOf('-dev') > -1;
  prod = !test && !dev;

} catch (error) { }

const config = Object.freeze({
  dev,
  test,
  prod,
  api: 'https://golfer.digitalcube.rs',
  // api: '//golfer.digitalcube.rs:9981',
  // api: '//localhost:9981',
  namespace: 'api'
});

export default config;