import React, { useState, useEffect } from 'react';
import { Navigation, Header, Loader, Placeholder, Item } from '../../../../components';
import { MessagingService, AuthService } from '../../../../services';

export default function Conversations(props) {
    let [conversations, setConversations] = useState([]);
    let [error, setError] = useState();
    let [loading, setLoading] = useState(true);
    let [myId, setMyId] = useState(null);
    
    
    const getConversations = async event => {
        try {   
            
            let data = await MessagingService.getConversations();

            let _data = data;
            _data.forEach(item => {
                item[0].receiver.image = `//golfer.digitalcube.rs/static/profiles/${item[0].receiver.image}.jpg`;
                item[0].sender.image = `//golfer.digitalcube.rs/static/profiles/${item[0].sender.image}.jpg`;
            });
            
            setConversations(_data);
            setLoading(false)
        } catch (error) {
            setError((error.toString && error.toString()) || error);
        }
    };

    
    useEffect(() => {
        setMyId(AuthService.getAccount().id)
        getConversations();
    }, []); 
    			
	const marginTop = {
        paddingBottom:'170px'
    }	

    return (
        <>
            <Header title="Conversations" goBack={() => {props.history.goBack()}} />
            <div className="container-box" >
                <div className="tournament-content ptop" style={marginTop}>
                {
                    loading ? 
                        <Placeholder type="courses" />
                        :
                            conversations.length > 0 ?
                                conversations.map((item) => {
                                        return (item[0].sender.id !== myId) ?

                                        
                                    <Item key={item[0].sender.id} 
                                        funct={''} 
                                        functMessage={''} 
                                        functType="messaging" 
                                        id={item[0].sender.id} 
                                        name={`${item[0].sender.first_name}  ${item[0].sender.last_name}`} 
                                        image={item[0].sender.image || ''} 
                                        favorite={''} 
                                        city={item[0].last_message}
                                        item={item[0]} />
                                        :  
                                    <Item key={item[0].receiver.id} 
                                        funct={''} 
                                        functMessage={''} 
                                        functType="messaging" 
                                        id={item[0].receiver.id} 
                                        name={`${item[0].receiver.first_name}  ${item[0].receiver.last_name}`} 
                                        image={item[0].receiver.image || ''} 
                                        favorite={''} 
                                        city={item[0].last_message}
                                        item={item[0]} />
                                    }
                                )
                                :
                                <p className="error-msg">There is no conversations.</p>
                }
                </div>
            </div>
        </>
    );
    
}

